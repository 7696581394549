import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home'
import Legals from '@/views/Legals'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/legals',
    name: 'Legals',
    component: Legals
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router
