import bowser from 'bowser'
import config from '../config'

const $ = document.documentElement
const classes = $.classList
let device = {}
let init = false

function add (values) {
  if (!Array.isArray(values)) values = [values]
  for (let i = 0; i < values.length; i++) {
    const v = values[i]
    classes.add(v)
    device[v] = true
  }
}

export default function addBowserClasses () {
  const result = bowser.getParser(window.navigator.userAgent).parsedResult

  if (init) return
  if (result.platform.type === 'mobile') {
    add(['mobile', 'touch'])
  }
  else if (result.platform.type == 'tablet' || window.orientation == 0 || window.orientation == 90 || window.orientation == -90 || window.orientation == 180) {
    add(['tablet', 'touch'])
    if (window.innerHeight > window.innerWidth) add('tablet-portrait')
  }
  else add('desktop')

  if (result.browser.name === 'Chrome') add('chrome')
  else if (result.browser.name === 'Safari') add('safari')
  else if (result.browser.name === 'Firefox') add('firefox')
  else if (result.browser.name === 'Edge') add('edge')
  else if (result.browser.name === 'Opera') add('opera')
  else add('ie')

  if (result.os.name === 'macOS') add('mac')
  else if (result.os.name === 'Linux') add('linux')
  else if (result.os.name === 'ios') add('ios')
  else if (result.os.name === 'Android') add('android')
  else if (result.os.name === 'Windowsphone') add('windowsphone')
  else add('windows')

  // if (bowser.msie && bowser.version >= 9 && bowser.version < 10) add('ie9')
  // else if (bowser.msie && bowser.version >= 10 && bowser.version < 11) add('ie10')
  // else if (bowser.msie && bowser.version >= 11 && bowser.version < 12) add('ie11')

  // if (bowser.webkit || bowser.safari || bowser.chrome) add('webkit')
  // else if (bowser.firefox || bowser.gecko) add('gecko')

  init = true
  config.setUp()
}
