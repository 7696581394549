<template>
  <div class="page page-legals">
    <template v-if="this.data?.sections">
      <component
        v-for="(section, i) in this.data?.sections"
        :key="i"
        :is="section.blocName"
        :content="section"
      ></component>
    </template>
  </div>
</template>

<script>
import Data from '@/data' 

export default {
  name: 'Legals',
  components: {
   
  },
  data () {
    return {
        data: Data['legals'],
    }
  }
}
</script>

