import store from '../store/store';
import config from '../config'

function Resize (){
  let resizeId

  function init () {
    resize();
    window.addEventListener('resize', resize);

    setTimeout(resize, 100)
    const inter = setInterval(resize, 500)
    setTimeout(() => clearInterval(inter), 5000)
  }

  function remove () {
    window.removeEventListener('resize', resize);
  }

  function resize (e) {
    store.resize.set({ width: window.innerWidth, height: window.innerHeight });

    // site-max-width: 1200
    // site-padding: 42
    // site-padding-mobile: 35

    if (window.innerWidth <= 800) {
      document.documentElement.style.setProperty('--vw', Math.min(window.innerWidth - (35 * 2), 1200) + 'px')
    } else {
      document.documentElement.style.setProperty('--vw', Math.min(window.innerWidth - (42 * 2), 1200) + 'px')
    }

    config.wide = window.innerWidth <= 1200;
    config.small = window.innerWidth <= 800;
    config.smaller = window.innerWidth <= 480;
  }

  return {
    init,
    remove,
  };
}
export default Resize();
