<template>
  <div class="grid-helper">
    <div class="grid-helper-desk">
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
    </div>

    <div class="grid-helper-mobile">
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
      <div class="grid-helper-col">
        <div class="col-inner"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GridHelper',
}
</script>

<style lang="scss" scoped>
  
</style>
