import { createStore } from './state'

const store = createStore({
  // Utils
  size: { w: 0, h: 0 },

  // global
  scroll: 0,
  mouse: [0, 0],
  resize: [0, 0],
})

export default store
