<template>
  <div>
    <router-view/>
    <GridHelper />
  </div>
</template>

<script>
import GridHelper from '@/components/GridHelper.vue'
import resize from '@/utils/resize'
import bowserToClasses from '@/utils/bowserToClasses'

export default {
  name: 'App',
  components: {
    GridHelper
  },
  mounted() {
    bowserToClasses()
    resize.init()

    this.bind()
  },
  methods: {
    bind () {
      window.addEventListener('resize', this.setAppHeight)
      this.setAppHeight()
    },
    setAppHeight () {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
  }
}
</script>
