<template>
  <section class="bloc-intro" :class="{ 'anime-finished': animeFinished }">
    <img class="bloc-intro__logo" :src="require(`@/${content.logo}`)">

    <div class="bloc-intro__player" ref="videoContainer">
      <!-- <div ref="playerDOM" class="bloc-video__video video" :data-plyr-provider="content.video_provider" :data-plyr-embed-id="content.video_id"></div> -->
      <!-- <video ref="playerDOM" class="bloc-video__video video" :src="require(`@/${content.video_test}`)"></video> -->
      <div ref="playerDOM" class="bloc-video__video video">
        <iframe
        src="https://player.vimeo.com/video/754483420"
        allowfullscreen
        allowtransparency
      ></iframe>
      </div>
      
    </div>

    <div class="bloc-intro__nuage-ctn" ref="nuageContainer">
      <img :src="require(`@/${content.img}`)" class="nuage" alt="" ref="nuage">
    </div>

    <div class="bloc-intro__title-ctn">
      <h1 v-html="content.title" ref="title"></h1>
      <span class="subtitle">{{ content.subtitle }}</span>
    </div>

    <div class="bloc-intro__texts">
      <a v-for="(item, i) in content.links" :key="i" :href="item.link" target="_blank" class="link">{{ item.text }}</a>
    </div>
  </section>
</template>

<script>
import Plyr from 'plyr'
import store from '../store/store'
import anime from 'animejs'
import config from '../config'

export default {
  name: 'PlayerAuto',
  props: {
    content: {
      type: Object,
      required: true
    },
  },
  data: () => {
    return {
      animeFinished: false
    }
  },
  mounted () {
     this.player = new Plyr(this.$refs.playerDOM, {
      controls: [],
      ratio: '16:9',
      autoplay: true,
      loop: {
        active: true,
      },
      muted: true,
      vimeo: { 
        muted: true,
      },
    })

    this.resizeFullWidthAndHeight()
    this.bind()

    this.player.on('playing', () => {
      this.player.volume = 0
      this.player.play()
      this.laucheAnime()
    });
  },
  methods: {
    bind() {
      store.resize.listen(this.resizeFullWidthAndHeight)
    },
    resizeFullWidthAndHeight () {
      const backgroundHeight = window.innerHeight
      const backgroundWidth = window.innerWidth

      let ratio = (backgroundWidth / backgroundHeight) * 100
      ratio = Math.round(ratio)
      ratio = ratio / 100

      if (ratio < 2.37) {
        this.$refs.videoContainer.style.height = '100%'
        this.$refs.videoContainer.style.width = `${Math.round(backgroundHeight * 2.38)}px`
      } else {
        this.$refs.videoContainer.style.width = '100%'
        this.$refs.videoContainer.style.height = `${Math.round(backgroundWidth / 2.38)}px`
      }
    },
    laucheAnime () {
      if (this.animeLaunched) return

      this.animeLaunched = true
      setTimeout(() => {
        this.$refs.nuageContainer.style.opacity = 1;
      }, 500)

      anime({
        targets: this.$refs.nuage,
        scale: !config.small ? [0.7, 2.5] : [1.3, 3.5],
        translateY: ['-10%', '-60%'],
        duration: 11000,
        easing: 'linear',
        delay: 1000,
      })

      anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        duration: 500,
        easing: 'linear',
        delay: 3500
      })

      anime({
        targets: this.$refs.title,
        scale: [0.3, 1],
        duration: 10000,
        easing: 'linear',
        delay: 3500,
        complete: () => {
          setTimeout(() => {
            this.animeFinished = true
          }, 1000)
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .bloc-intro {
    width: 100vw;
    height: var(--app-height);
    background: $th-white;
    overflow: hidden;
    position: relative;

    &__logo {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
      width: 183px;
      mix-blend-mode: difference;
      opacity: 0;
      transition: opacity 0.5s $quad-ease-out;

      .anime-finished & {
        opacity: 1;
      }
    }

    &__player {
      pointer-events: none;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -67%);

      video {
        object-fit: cover;
      }
    }

    &__title-ctn {
      mix-blend-mode: difference;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      text-align: center;

      h1 {
        @include title($level: 1, $color: $th-white, $uppercase: true);
        display: block;
        opacity: 0;
        transform-origin: center;
        transform: scale(0.3);
      }

      .subtitle {
        @include paragraph($type: 'big', $color: $th-white, $uppercase: false);
        color: $th-white;
        display: block;
        margin-top: 15px;
        opacity: 0;
        transition: opacity 0.5s $quad-ease-out;

        .anime-finished & {
          opacity: 1;
        }
      }
    }

    &__nuage-ctn {
      position: absolute;
      z-index: 2;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      bottom: -40%;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.5s $quad-ease-out;

      @include media($until: small) {
        bottom: 10%;
      }

      .nuage {
        transform: scale(0.8);
        width: 100%;
        transform-origin: center;
      }
    }

    &__texts {
      mix-blend-mode: difference;
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      bottom: 10px;
      opacity: 0;
      z-index: 2;
      pointer-events: none;
      transition: opacity 0.5s $quad-ease-out;

      .anime-finished & {
        opacity: 1;
        pointer-events: auto;
      }

      .link {
        @include paragraph($type: 'big', $color: $th-white, $uppercase: false);
        display: inline-block;
        margin: 0 8px;
      }
    }
  }
</style>
