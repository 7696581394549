export default {
  home: {
    title: '',
    header: '',
    sections: [
      {
        blocName: 'Intro',
        logo: 'assets/images/icons/logo.svg',
        video_provider: 'vimeo',
        // video_id: '726644975',
        video_id: '754483420',
        video_test: 'assets/images/video/test.mp4',
        img: 'assets/images/nuage.png',
        title: 'LET THE<br>GAME BEGIN',
        subtitle: 'Release November 24',
        links: [
          {
            text: '@etherfirm',
            link: 'https://instagram.com/etherfirm?igshid=YmMyMTA2M2Y='
          },
          {
            text: 'afk@etherfirm.co',
            link: 'mailto:afk@etherfirm.co'
          }
        ]
      },
    ]
  },

  legals: {

  },
}